<template>
  <b-input
    v-model="maskedValue"
    v-bind="$attrs"
    :data-mask="inputElementUniqueId"
  />
</template>

<script >
import { uuid } from 'vue-uuid';
import { useMask } from './mask';
import { computed, ref, watch } from '@vue/composition-api';
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: String,
    default: ''
  },
  mask: {
    type: Object,
    default: null
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const inputElementUniqueId = ref(uuid.v4());
  const inputElementSelector = computed(() => inputElementUniqueId.value ? `[data-mask="${inputElementUniqueId.value}"]` : '');
  const {
    maskedValue
  } = useMask(props, emit, inputElementSelector.value);
  watch(maskedValue, (newValue, _oldValue) => {
    emit('mask-value-update', newValue);
  });
  return {
    inputElementUniqueId,
    maskedValue
  };
};
export default __sfc_main;
</script>
